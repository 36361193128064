import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableBioSafetyCabinets = loadable(() => import('components/BiologicalSafetyCabinetsPage'), {
  fallback: (
    <Spinner />
  ),
});
const BiologicalSafetyPage = () => {
  return <LoadableBioSafetyCabinets />;
};
export default BiologicalSafetyPage;
